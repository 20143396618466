import { LocalData } from 'ng2-completer';
import { Observable } from 'rxjs';

export interface User {
  id?: number,
  name:string,
  type?: string,
  user_type?: number,
  phone: string,
  image?: string,
  age: number,
  weight: string,
  length: string,
  blood: string,
  illness: string,
  illness_date: string,
  email: string,
  password: string,
  senderType?: string
}


export abstract class UserData {
  abstract getUsers(): Observable<User[]>;
  abstract saveUser(user: User): Observable<User[]>;
  abstract updateUser(user: User): Observable<User[]>;
  abstract deleteUser(id: any): Observable<User[]>;
}
