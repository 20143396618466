<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><span>Ambulance</span></a>
  </div>
  <!--<nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>-->
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" (click)="logout">
      <button class="btn btn-success" (click)="logout()"
               >Logout
    </button>
    </nb-action>
  </nb-actions>
</div>
