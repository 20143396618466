import { Observable } from 'rxjs';
import { User } from './users';
import { Paramedics } from './paramedics';
export interface Request {
  id?: number,
  u_name:string,
  u_age: number,
  u_gender: string,
  u_blood_type: string,
  illness_details: string,
  r_date: string,
  r_status: string,
  r_time: string,
  user_name?: string,
  para_name?: string,
  paramedics?: Paramedics
}

export abstract class RequestData {
  abstract getRequests(id:any, type: any): Observable<Request[]>;

}
