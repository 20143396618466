import { of , Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Request, RequestData } from '../data/request'
import { HttpClient, HttpHeaders } from '@angular/common/http';

const API_URL: string = 'https://hospital.erp-eng.site/api';

//const API_URL: string = 'http://127.0.0.1:8000/api';

@Injectable()
export class RequestService {

    constructor(private http: HttpClient) {
        this.init();
    }

    async init() {
   //  this.getRequests(null)
    }

    getRequests(id:any, type: any): Observable<Request[]> {
      if(id)
      return this.http.get<Request[]>(API_URL + '/adminGetAllRequest/'+ id + '/' + type);
      else
      return this.http.get<Request[]>(API_URL + '/adminGetAllRequest');
  }
}
