import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthJWTToken, NbAuthModule, NbDummyAuthStrategy, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
} from './utils';
import { UserData } from './data/users';
import { ParamedicsData } from './data/paramedics';
import { UserActivityData } from './data/user-activity';
import { TrafficListData } from './data/traffic-list';
import { RequestData } from './data/request';
import { TemperatureHumidityData } from './data/temperature-humidity';
import { StatsBarData } from './data/stats-bar';
import { StatsProgressBarData } from './data/stats-progress-bar';

import { UserService } from './mock/users.service';
import { ParamedicsService } from './mock/paramedics.service';
import { RequestService } from './mock/request.service';
import { UserActivityService } from './mock/user-activity.service';
import { TemperatureHumidityService } from './mock/temperature-humidity.service';
import { StatsBarService } from './mock/stats-bar.service';
import { StatsProgressBarService } from './mock/stats-progress-bar.service';
import { MockDataModule } from './mock/mock-data.module';

const socialLinks = [
  {
    url: 'https://github.com/akveo/nebular',
    target: '_blank',
    icon: 'github',
  },
  {
    url: 'https://www.facebook.com/akveo/',
    target: '_blank',
    icon: 'facebook',
  },
  {
    url: 'https://twitter.com/akveo_inc',
    target: '_blank',
    icon: 'twitter',
  },
];

const DATA_SERVICES = [
  { provide: UserData, useClass: UserService },
  { provide: ParamedicsData, useClass: ParamedicsService },
  { provide: RequestData, useClass: RequestService },
  { provide: UserActivityData, useClass: UserActivityService },
  { provide: TemperatureHumidityData, useClass: TemperatureHumidityService },
  { provide: StatsBarData, useClass: StatsBarService },
  { provide: StatsProgressBarData, useClass: StatsProgressBarService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
       /* token: {
          class: NbAuthJWTToken,
          key: 'data.token',
        },*/
        baseEndpoint: 'https://hospital.erp-eng.site/api',
        login: {
          redirect: {
            success: '/pages/user-table',
            failure: null
          },
          endpoint: '/loginAdmin',
          method: 'post',
        },
        register: false,
        logout: {
          endpoint: '/logout',
          method: 'post',
          redirect: {
            success: '/auth/login',
            failure: null
          },
        },
      }),
    ],
    forms: {
     /* login: {
        socialLinks: socialLinks,
      },
     /* register: {
        socialLinks: socialLinks,
      },*/
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  PlayerService,
  SeoService,
  StateService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
