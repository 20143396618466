import { Observable } from 'rxjs';

export interface Paramedics {
  id?: number,
  name:string,
  user_type?: string,
  phone: string,
  image?: string,
  age: number,
  experience: string,
  car_num: string,
  status?: string,
  password: string,
  senderType?: string
}


export abstract class ParamedicsData {
  abstract getParamedics(): Observable<Paramedics[]>;
  abstract saveParamedics(paramedics: Paramedics): Observable<Paramedics[]>;
  abstract updateParamedics(paramedics: Paramedics): Observable<Paramedics[]>;
  abstract deleteParamedics(id: any): Observable<Paramedics[]>;
}
