import { of , Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { User, UserData } from '../data/users';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalDataSource } from 'ng2-smart-table';

const API_URL: string = 'https://hospital.erp-eng.site/api';

//const API_URL: string = 'http://127.0.0.1:8000/api';

@Injectable()
export class UserService {

    constructor(private http: HttpClient) {
        this.init();
    }

    async init() {
     this.getUsers()
    }
    source:LocalDataSource = new LocalDataSource();
    getUsers(): Observable<User[]> {
      return this.http.get<User[]>(API_URL + '/users');
     }
   saveUser(user: User): Observable<any> {
    return this.http.post<any>(API_URL + '/registerUser', user);
  }
  updateUser(user: User): Observable<any> {
    return this.http.post<any>(API_URL + '/updateUser', user);
  }
  deleteUser(id: any): Observable<any> {
    return this.http.post<any>(API_URL + '/deleteUser', id);
  }
}
