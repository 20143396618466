import { of , Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Paramedics, ParamedicsData } from '../data/paramedics';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const API_URL: string = 'https://hospital.erp-eng.site/api';

//const API_URL: string = 'http://127.0.0.1:8000/api';

@Injectable()
export class ParamedicsService {

    constructor(private http: HttpClient) {
        this.init();
    }

    async init() {
     this.getParamedics()
    }

    getParamedics(): Observable<Paramedics[]> {
      return this.http.get<Paramedics[]>(API_URL + '/getAllParamedics');
  }
   saveParamedics(user: Paramedics): Observable<any> {
    return this.http.post<any>(API_URL + '/adminAddParamedic', user);
  }
  updateParamedics(user: Paramedics): Observable<any> {
    return this.http.post<any>(API_URL + '/updateParamedicInfo', user);
  }
  deleteParamedics(id: any): Observable<any> {
    return this.http.post<any>(API_URL + '/adminDeleteParamedic', id);
  }
}
